<template>
  <div class="container">
    <div class="row">
      <!-- Primer conjunto de tarjetas -->
      <div v-motion-pop class="col-12 mb-5 mt-5 d-none d-sm-block ">
        <h4 class="section-title">Featured projects</h4>
        <div class="d-flex card-container">
          <CardInfo 
            :imageUrl="imageUrl3"
            title="Voice GPT"
            description="Vue3, Express, AWS Polly y OpenAI"
            link="https://www.linkedin.com/posts/valentino-ghitti-b6042120a_buenass-dejo-un-proyectito-en-el-que-estuve-activity-7100570896381198336-ioX6?utm_source=share&utm_medium=member_desktop"
          />
          <CardInfo 
            title="CHORD APP"
            description="Vue3, Firebase, Vuex"
            :imageUrl="imageUrl"
            link="https://www.linkedin.com/posts/valentino-ghitti-b6042120a_vue-frontend-firebase-activity-6980613223297241089-dcM7?utm_source=share&utm_medium=member_desktop"
          />
          <CardInfo 
            :imageUrl="imageUrl2"
            title="PET CARE"
            description="Vue2, Vuetify, Vuex, Localstorage"
            link="https://clinquant-crisp-2d48bd.netlify.app/"
          />
        </div>
      </div>
      <br>

      <!-- Segundo conjunto de tarjetas (Swiper) -->
      <div class="col-12 mt-3">
        <div class="text-white">
          <h4 class="section-title">Other projects</h4>
        </div>

        <swiper
          :slidesPerView="4"
          :loop="false"
          :navigation="false"
          :mousewheel="true"
          :pagination="{ clickable: true }"
          :modules="modules"
          :breakpoints="swiperOptions.breakpoints"
          class="mySwiper"
          v-motion-pop
        >
          <swiper-slide>
            <CardInfoxs
              :imageUrl="require('../assets/uno.jpg')"
              description="Vue3"
              link="https://pokemongame-vue-3.netlify.app/"
              title="POKEMON APP"
            />
          </swiper-slide>
          <swiper-slide>
            <CardInfoxs
              description="Nuxt.js, Pinia, Tailwind"
              title="Spotify Clone"
              link="https://www.linkedin.com/posts/valentino-ghitti-b6042120a_frontend-javascript-vue-activity-7094728622506176512-RgIe?utm_source=share&utm_medium=member_desktop"
              :imageUrl="require('../assets/doce.jpg')"
            />
          </swiper-slide>
          <swiper-slide>
            <CardInfoxs
              description="Vue3"
              title="SHOPPING CART"
              link="https://beer-cart-vue.netlify.app/"
              :imageUrl="require('../assets/dos.jpg')"
            />
          </swiper-slide>
          <swiper-slide>
            <CardInfoxs
              description="Vue3/Vuex"
              link="https://rick-and-morty-wiki-vue.netlify.app/"
              title="RICKN'MORTY APP"
              :imageUrl="require('../assets/cuatro.jpg')"
            />
          </swiper-slide>
          <swiper-slide>
            <CardInfoxs
              description="Vue3"
              title="TODO LIST APP"
              link="https://to-do-list-vue3.netlify.app/"
              :imageUrl="require('../assets/tres.jpg')"
            />
          </swiper-slide>
          <swiper-slide>
            <CardInfoxs
              description="JS/SASS"
              title="Rapiboy landing page"
              link="https://challenge-rapiboy.netlify.app/"
              :imageUrl="require('../assets/rapibot.png')"
            />
          </swiper-slide>
          <swiper-slide>
            <CardInfoxs
              description="Vue3"
              title="INDECISION APP"
              link="https://akinator-app.netlify.app/"
              :imageUrl="require('../assets/project-siete.jpg')"
            />
          </swiper-slide>
          <swiper-slide>
            <CardInfoxs
              description="Node.js, Inquirer, JS"
              title="To do list APP"
              link="https://www.linkedin.com/posts/valentino-ghitti-b6042120a_hice-una-app-interactiva-de-consola-todo-activity-7074939857701154816-0kU6?utm_source=share&utm_medium=member_desktop"
              :imageUrl="require('../assets/ocho.160e4ad0.jpg')"
            />
          </swiper-slide>
          <swiper-slide>
            <CardInfoxs
              description="Node.js, Inquirer"
              title="MAPS APP"
              link="https://www.linkedin.com/posts/valentino-ghitti-b6042120a_node-javascript-nodejs-activity-7076549044025593856-Uh3T?utm_source=share&utm_medium=member_desktop"
              :imageUrl="require('../assets/nueve.jpg')"
            />
          </swiper-slide>
          <swiper-slide>
            <CardInfoxs
              description="Next.js, TypeScript"
              title="To do list APP"
              link="https://www.linkedin.com/posts/valentino-ghitti-b6042120a_react-next-javascript-activity-7084220421931212800-nMnW?utm_source=share&utm_medium=member_desktop"
              :imageUrl="require('../assets/diez.jpg')"
            />
          </swiper-slide>
          <swiper-slide>
            <CardInfoxs
              description="Nuxt.js, TypeScript"
              title="MovieApp"
              link="https://www.linkedin.com/posts/valentino-ghitti-b6042120a_nuxt-vue-frontend-activity-7089200935918301184-fECU?utm_source=share&utm_medium=member_desktop"
              :imageUrl="require('../assets/once.jpg')"
            />
          </swiper-slide>
          <swiper-slide>
            <CardInfoxs
              description="Vue2"
              title="Fortune Wheel"
              link="https://fortune-wheel-vue2.netlify.app/"
              :imageUrl="require('../assets/wfortune.png')"
            />
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper-bundle.css";
import { Mousewheel, Navigation } from "swiper";
import CardInfo from '../components/CardInfo.vue';
import CardInfoxs from '../components/CardInfoxs.vue';
import juckeboximg from '../assets/jukeboxv.png'
import dashboardimg from '../assets/petcare.png'
import voiceImg from '../assets/quince.jpg'
export default {
  name: 'Skills',
  data () {
    return {
      imageUrl: juckeboximg,
      imageUrl2: dashboardimg,
      imageUrl3: voiceImg,

      swiperOptions: {
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 10
          },
          480: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 10
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50
          },
          1440: {
            slidesPerView: 4,
            spaceBetween: 10
          },
          2500: {
            slidesPerView: 4,
            spaceBetween: 10
          }
        }
      }
    }
  },


  components: {
    Swiper,
    SwiperSlide,
    CardInfo,
    CardInfoxs
  },
  setup() {
    return { modules: [Mousewheel, Navigation] }
  } 
}
</script>

<style scoped>
/* Asegúrate de que el espaciado y alineación sean consistentes */
.card-container {
  gap: 15px;
}

.test {
  height: 190px;
  width: 304px;
}

.section-title {
  font-size: 1.5rem; /* Ajusta el tamaño de fuente si es necesario */
  color: #fff;
  margin-bottom: 20px;
}

:root {
  --swiper-navigation-size: 84px;
  --swiper-navigation-color: var(--bs-yellow);
}

.swiper-slide {
  height: auto;
}

/* Ajustes de media queries para mejorar el diseño responsivo */
@media screen and (max-width: 320px) {
  :root {
    --swiper-navigation-size: 44px;
  }
}

@media screen and (max-width: 375px) {
  :root {
    --swiper-navigation-size: 44px;
  }
}

@media screen and (max-width: 425px) {
  :root {
    --swiper-navigation-size: 44px;
  }
}

@media screen and (max-width: 768px) {
  :root {
    --swiper-navigation-size: 44px;
  }
}

@media screen and (max-width: 1024px) {
  :root {
    --swiper-navigation-size: 44px;
  }
}

@media screen and (min-width: 1821px) and (max-width: 2560px) and (min-resolution: 192dpi) {
  :root {
    --swiper-navigation-size: 100px;
  }
}
</style>
