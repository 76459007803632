<template>
  <div class="container-fluid mt-5">
    <div class="row align-items-start mt-5">
      <div class="d-flex justify-content-center" v-motion-fade :delay="200">
        <div class="col-10 d-flex flex-wrap justify-content-center">
          <div class="col-12 col-md-2 mb-4">
            <h3 class="fs-5 text-white pt-5 pb-2 ">Languages</h3>
            <div class="d-flex flex-wrap">
              <i class="devicon-javascript-plain"></i>
              <i class="devicon-typescript-plain"></i>
            </div>
          </div>
          
          <div class="col-12 col-md-3 mb-4 mx-2">
            <h3 class="fs-5 text-white pt-5 pb-2 ">JS/TS Frameworks</h3>
            <div class="d-flex flex-wrap">
              <i class="devicon-vuejs-plain"></i>
              <i class="devicon-react-original"></i>
              <i class="devicon-nestjs-original"></i>
              <i class="devicon-jest-plain"></i>
              <i class="devicon-nuxtjs-plain"></i>
              <i class="devicon-nextjs-original-wordmark"></i>
              <i class="devicon-express-original"></i>
            </div>
          </div>
          
          <div class="col-12 col-md-3 mb-4 mx-2">
            <h3 class="fs-5 text-white pt-5 pb-2 ">HTML & CSS Frameworks</h3>
            <div class="d-flex flex-wrap">
              <i class="devicon-bootstrap-plain"></i>
              <i class="devicon-tailwindcss-original"></i>
              <i class="devicon-sass-original"></i>
              <i class="devicon-quasar-plain"></i>
              <i class="devicon-vuetify-plain"></i>
              <i class="devicon-materialui-plain"></i>
            </div>
          </div>
          
          <div class="col-12 col-md-3 mb-4 mx-2">
            <h3 class="fs-5 text-white pt-5 pb-2 ">Interesting libraries</h3>
            <div class="d-flex flex-wrap">
              <i class="devicon-redux-original"></i>
              <i class="devicon-graphql-plain"></i>
              <i class="devicon-sequelize-plain"></i>
              <i class="devicon-mongoose-original"></i>
            </div>
          </div>

          <div class="col-12 col-md-3 mb-4 mx-2 ml-5">
            <h3 class="fs-5 text-white pt-5 pb-2 ">Platforms</h3>
            <div class="d-flex flex-wrap">
              <i class="devicon-firebase-plain"></i>
              <i class="devicon-vault-original"></i>
              <i class="devicon-amazonwebservices-plain-wordmark"></i>
              <i class="devicon-docker-plain"></i>
              <i class="devicon-googlecloud-plain"></i>
              <i class="devicon-netlify-plain"></i>
            </div>
          </div>
                    
          <div class="col-12 col-md-4 mb-4 mx-2">
            <h3 class="fs-5 text-white pt-5 pb-2 ">Databases</h3>
            <div class="d-flex flex-wrap">
              <i class="devicon-mongodb-plain"></i>
              <i class="devicon-mysql-original"></i>
              <i class="devicon-postgresql-plain"></i>
            </div>
          </div>
                    
          <div class="col-12 col-md-4 mb-4 mx-2">
            <h3 class="fs-5 text-white pt-5 pb-2 ">Tooling</h3>
            <div class="d-flex flex-wrap">
              <i class="devicon-notion-plain"></i>
              <i class="devicon-git-plain"></i>
              <i class="devicon-postman-plain"></i>
              <i class="devicon-github-original"></i>
              <i class="devicon-figma-plain"></i>
              <i class="devicon-gitlab-plain"></i>
              <i class="devicon-confluence-plain"></i>
              <i class="devicon-jira-plain"></i>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {}
}
</script>

<style scoped>
i {
  background-color: #1E1E1E; /* Fondo gris */
  border-radius: 50%; /* Borde redondeado */
  padding: 10px; /* Espaciado interior para que el fondo gris sea visible */
}

img {
  background-color: #1E1E1E; /* Fondo gris */
  border-radius: 100%; /* Borde redondeado */
  padding: 15px; /* Espaciado interior para que el fondo gris sea visible */
}

@media (max-width: 576px) {
  .container-fluid {
    padding-top: 900px; /* Ajusta este valor según lo necesites */
  }
  
  .col-12 {
    padding-top: 20px; /* También puedes agregar un padding extra si es necesario */
  }
}

i {
  font-size: 35px;
  color: #FFB833;
  margin: 10px;
}

.skill-image {
  width: 100%;
  height: auto;
  max-width: 40px;
  object-fit: contain;
}

.boostrap-image {
  filter: brightness(0) saturate(100%) invert(81%) sepia(74%) saturate(4590%) hue-rotate(334deg) brightness(106%) contrast(97%);
}

.vuex-image {
  filter: brightness(0) saturate(100%) invert(81%) sepia(74%) saturate(4590%) hue-rotate(334deg) brightness(106%) contrast(97%);
  height: 50px;
}

.pinia-img {
  height: 45px;
  filter: brightness(0) saturate(100%) invert(81%) sepia(74%) saturate(4590%) hue-rotate(334deg) brightness(106%) contrast(97%);
}

.fire-img {
  filter: brightness(0) saturate(100%) invert(81%) sepia(74%) saturate(4590%) hue-rotate(334deg) brightness(106%) contrast(97%);
}

.datadog-image {
  filter: brightness(0) saturate(100%) invert(81%) sepia(74%) saturate(4590%) hue-rotate(334deg) brightness(106%) contrast(97%);
}
</style>
